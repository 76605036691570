import styled from "styled-components";

import FAQbg from "../../../../assets/images/bg/FAQ-v7.webp";

const FAQStyleWrapper = styled.section`
background: url(${FAQbg});
background-size: cover;
background-repeat: no-repeat;

  padding-bottom: 80px;
  padding-top: 30px;

  .Degenesys_faq_content {
    max-width: 770px;
    width: 100%;
    margin: auto;
    position: relative;
  }

  .Degenesys_faq_questions {
    margin-top: 57px;
    position: relative;

    .faq_questions {
      position: relative;
      z-index: 1;

      .accordion__item + .accordion__item 
        margin-top: 10px;
      }
    }
  }
  .accordion__item {
    margin-bottom: 10px;
    border-radius:40px;
    background: rgba(107, 220, 254, 0.1);
  }
  .accordion__header {
    h5 {
      display: flex;
      align-items: center;
      height: 76px;
      margin-bottom: 0px;
      padding: 0 29px;
      color: rgba(107, 220, 254, 1);
      font-size: 24px;
      line-height: 34px;
      text-transform: none;
    }
  }

  .accordion__body {
    p {
      padding: 0px 48px 24px 29px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 0px;
      max-width: 100%;
    }
  }

  .faq_bg_wattermark {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }

  @media only screen and (max-width: 991px) {
    .accordion__header {
      h5 {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .accordion__header {
      h5 {
        font-size: 20px;
        line-height: 25px;
      }
    }

    .accordion__body {
      p {
        font-size: 14px;
        line-height: 25px;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .accordion__header {
      h5 {
        font-size: 18px;
      }
    }
  }
`;

export default FAQStyleWrapper;
