import {FaTwitter} from "react-icons/fa";

import twitter from "../images/icon/twitter.webp";

import opensea from "../images/icon/OpenSea.png";


const data = [
  {
    thumb: opensea,
    url: "https://opensea.io/collection/degenesys",
  },
  {
    thumb: twitter,
    url: "https://twitter.com/DegenesysNFT",
  },
];

export default data;